<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          :rules="!campoParaClassificacao ? 'required' : ''"
        >
          <v-select
            :value="campoParaClassificacao"
            :items="camposParaClassificacao"
            :error-messages="errors"
            name="campoParaClassificacao"
            label="Campo para classificação dos dados"
            hint="Clique no ícone ao lado para carregar os dados para classificação"
            @change="setCampoParaClassificacao"
          >
            <v-tooltip
              slot="append-outer"
              bottom
            >
              <template v-slot:activator="{ on }">
                <span
                  v-show="!loading"
                  @click="getRangeValoresPorCampo()"
                >
                  <v-icon
                    v-on="on"
                    color="primary"
                    dark
                    style="cursor: pointer"
                    loading
                  >
                    mdi-play-speed
                  </v-icon>
                </span>
                <v-progress-circular
                  v-show="loading"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="25"
                />
              </template>
              <span>Clique aqui para carregar os dados para classificação</span>
            </v-tooltip>
          </v-select>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert
          dense
          outlined
          type="info"
          color="blue"
          class="mb-3 py-1 px-1"
          v-if="
            visibleSecondSection &&
            totalIntervalosDadosParaClassificacao >= maximoIntervalosDados
          "
        >
          Para não deixar o mapa visualmente poluído, o máximo são
          {{ maximoIntervalosDados }} intervalos de dados exibidos para
          classificação.
        </v-alert>
        <v-simple-table
          v-if="!loadingIntervalos"
          :style="!visibleSecondSection && 'opacity: 0.3'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Dado dentro do intervalo</th>
                <th class="text-left">
                  {{ labelColorIcon }}
                </th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(infoFeicao, index) in iconesCoresFeicoes"
                :key="index"
              >
                <td>
                  <v-range-slider
                    :value="infoFeicao.range"
                    :color="infoFeicao.cor"
                    :min="valorMinimo"
                    :max="valorMaximo"
                    :step="step"
                    @change="
                      (novoIntervalo) => atualizaIntervalo(index, novoIntervalo)
                    "
                    hide-details
                    class="align-center"
                    :disabled="!visibleSecondSection"
                  >
                    <template v-slot:prepend>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            :value="infoFeicao.range[0]"
                            :min="valorMinimo"
                            :max="valorMaximo"
                            :step="step"
                            class="mt-0 pt-0 align-center-tfnumber input-range-slider-sg"
                            hide-details
                            single-line
                            type="number"
                            @input="
                              (novoIntervaloInicio) =>
                                atualizaIntervalo(index, [
                                  novoIntervaloInicio,
                                  infoFeicao.range[1]
                                ])
                            "
                          />
                        </template>
                        <span>{{ infoFeicao.range[0] }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            :value="infoFeicao.range[1]"
                            :min="valorMinimo"
                            :max="valorMaximo"
                            :step="step"
                            class="mt-0 pt-0 align-center-tfnumber input-range-slider-sg"
                            hide-details
                            single-line
                            type="number"
                            @input="
                              (novoIntervaloFim) =>
                                atualizaIntervalo(index, [
                                  infoFeicao.range[0],
                                  novoIntervaloFim
                                ])
                            "
                          />
                        </template>
                        <span>{{ infoFeicao.range[1] }}</span>
                      </v-tooltip>
                    </template>
                  </v-range-slider>
                </td>
                <td>
                  <input-icon-color
                    label=""
                    :selectedColor="infoFeicao.cor"
                    :selectedIcon="infoFeicao.icone"
                    @colorSelected="(novaCor) => atualizaCor(index, novaCor)"
                    @iconSelected="
                      (novoIcone) => atualizaIcone(index, novoIcone)
                    "
                    :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                    :disabled="!visibleSecondSection"
                  />
                </td>
                <td class="pb-7">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        min-width="0"
                        icon
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="remover(index)"
                        :disabled="!visibleSecondSection"
                      >
                        <v-icon small>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td>Para outros dados</td>
                <td>
                  <input-icon-color
                    label=""
                    :selectedColor="iconeCorOutrasFeicoes.cor"
                    :selectedIcon="iconeCorOutrasFeicoes.icone"
                    @colorSelected="
                      setIconeCorOutrasFeicoes({ icone: '', cor: $event })
                    "
                    @iconSelected="
                      setIconeCorOutrasFeicoes({ icone: $event, cor: '' })
                    "
                    :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                    :disabled="!visibleSecondSection"
                  />
                </td>
                <td class="pb-7">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#2B60D6"
                        min-width="0"
                        icon
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="openDialogAdicionar()"
                        :disabled="
                          !visibleSecondSection || disabledBtnAdicionar
                        "
                      >
                        <v-icon small> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          v-else
          class="d-flex justify-center align-center"
          style="height: 200px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :width="4"
            :size="100"
          />
        </div>

        <v-dialog
          v-model="dialog"
          max-width="400"
        >
          <v-card>
            <v-card-title class="headline"> Novo Intervalo </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <p class="text-caption">Dado dentro do intervalo</p>
                    <v-range-slider
                      v-model="dialogsData.range"
                      :min="valorMinimo"
                      :max="valorMaximo"
                      :step="step"
                      :color="dialogsData.cor"
                      hide-details
                      class="align-center"
                      :disabled="!visibleSecondSection"
                    >
                      <template v-slot:prepend>
                        <v-text-field
                          :value="dialogsData.range[0]"
                          :min="valorMinimo"
                          :max="valorMaximo"
                          :step="step"
                          class="mt-0 pt-0 align-center-tfnumber"
                          hide-details
                          single-line
                          type="number"
                          style="width: 70px"
                          @change="$set(dialogsData.range, 0, $event)"
                        />
                      </template>
                      <template v-slot:append>
                        <v-text-field
                          :value="dialogsData.range[1]"
                          :min="valorMinimo"
                          :max="valorMaximo"
                          :step="step"
                          class="mt-0 pt-0 align-center-tfnumber"
                          hide-details
                          single-line
                          type="number"
                          style="width: 70px"
                          @change="$set(dialogsData.range, 1, $event)"
                        />
                      </template>
                    </v-range-slider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <input-icon-color
                      label=""
                      :selectedColor="dialogsData.cor"
                      :selectedIcon="dialogsData.icone"
                      @colorSelected="(cor) => (dialogsData.cor = cor)"
                      @iconSelected="(icone) => (dialogsData.icone = icone)"
                      :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                      :disabled="!visibleSecondSection"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                @click="adicionar()"
              >
                Adicionar
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogIntervalos"
          max-width="500"
          persistent
        >
          <validation-observer v-slot="{ invalid }">
            <v-card>
              <v-card-title class="headline">
                Conjunto de Intervalos
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pb-0"
                    >
                      <div class="tfnumber-label">
                        Quantos deseja adicionar?
                      </div>
                      <v-text-field
                        class="align-center-tfnumber pt-0 mt-0"
                        v-model="qtdIntervalos"
                        append-outer-icon="mdi-plus"
                        prepend-icon="mdi-minus"
                        @click:append-outer="
                          manageIntervalos(dialogIntervalosData.length + 1)
                        "
                        @click:prepend="
                          manageIntervalos(dialogIntervalosData.length - 1)
                        "
                        @input="manageIntervalos($event)"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-scroll
                    style="max-height: 250px; overflow-y: auto"
                  >
                    <v-col
                      v-for="(intervalo, i) in dialogIntervalosData.length"
                      :key="i"
                      cols="12"
                      class="py-0"
                    >
                      <input-icon-color
                        :label="
                          tipoDadoGeografico !== 'PONTO'
                            ? 'Cor dos pontos no mapa'
                            : ''
                        "
                        :selectedColor="dialogIntervalosData[i].cor"
                        :selectedIcon="dialogIntervalosData[i].icone"
                        :rules="{ min: 7, required: true }"
                        :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                        @colorSelected="
                          (cor) => (dialogIntervalosData[i].cor = cor)
                        "
                        @iconSelected="
                          (icone) => (dialogIntervalosData[i].icone = icone)
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  @click="adicionarConjuntoIntervalos()"
                  :disabled="invalid || dialogIntervalosData.length == 0"
                >
                  Adicionar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogIntervalos = false"
                >
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';

const camposIgnorados = ['dist', 'bdgd_versao'];

const tiposPermitidos = ['decimal', 'inteiro'];

const corPadrao = '#FF0000';

export default {
  components: {
    InputIconColor: () => import('@/components/general/InputIconColor.vue')
  },
  props: {
    tipoDadoGeografico: {
      type: String,
      required: true
    },
    campos: {
      type: Array,
      default: () => []
    },
    tiposDadosCampos: {
      type: Object,
      default: () => {}
    },
    relatorio: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      maximoIntervalosDados: 10,
      dialog: false,
      dialogsData: {
        range: [0, 0],
        cor: corPadrao,
        icone: ''
      },
      step: 0.01,
      loading: false,
      dialogIntervalos: false,
      dialogIntervalosData: [],
      loadingIntervalos: false,
      qtdIntervalos: 0
    };
  },
  computed: {
    campoParaClassificacao() {
      return this.$store.getters.getSimbologiaConfigGraduada
        .campoParaClassificacao;
    },

    camposParaClassificacao() {
      return this.campos
        .filter((campo) => {
          return (
            !camposIgnorados.includes(campo) &&
            tiposPermitidos.includes(this.tiposDadosCampos[campo])
          );
        })
        .sort();
    },

    iconesCoresFeicoes() {
      return this.$store.getters.getSimbologiaConfigGraduada.iconesCoresFeicoes;
    },

    iconeCorOutrasFeicoes() {
      return this.$store.getters.getSimbologiaConfigGraduada
        .iconeCorOutrasFeicoes;
    },

    totalIntervalosDadosParaClassificacao() {
      return this.iconesCoresFeicoes.length;
    },

    valorMinimo() {
      return this.$store.getters.getSimbologiaConfigGraduada.valorMinimo;
    },

    valorMaximo() {
      return this.$store.getters.getSimbologiaConfigGraduada.valorMaximo;
    },

    disabledBtnAdicionar() {
      return (
        this.$store.getters.getSimbologiaConfigGraduada.valorMinimo ==
        this.$store.getters.getSimbologiaConfigGraduada.valorMaximo
      );
    },

    visibleSecondSection() {
      return this.$store.getters.getSimbologiaConfigGraduada.iconesCoresFeicoes
        .length;
    },

    labelColorIcon() {
      return this.tipoDadoGeografico == 'PONTO' ? 'Ícone e cor' : 'Cor';
    }
  },
  methods: {
    getRangeValoresPorCampo() {
      if (!this.campoParaClassificacao) {
        this.$toast.error(
          'Selecione um campo para a classificação dos dados',
          '',
          { position: 'topRight' }
        );
        return;
      }

      const relatorioId = this.relatorio.id;
      const indiceCampoCsv = this.campos.findIndex(
        (campo) => campo === this.campoParaClassificacao
      );

      if (indiceCampoCsv < 0) {
        this.$toast.error(
          'Selecione um campo para a classificação dos dados',
          '',
          { position: 'topRight' }
        );
        return;
      }

      this.loading = true;
      RelatoriosService.getRangeValoresPorCampo(relatorioId, indiceCampoCsv)
        .then((response) => {
          let { min, max } = response.data;
          min = Number(min || 0);
          max = Number(max || 0);

          this.setValorMinimo(min);
          this.setValorMaximo(max);

          if (this.disabledBtnAdicionar) {
            this.setCoresFeicoes([
              {
                range: [min, max],
                cor: corPadrao,
                icone: ''
              }
            ]);
            this.setIconeCorOutrasFeicoes({ icone: '', cor: corPadrao });
            return;
          }

          this.dialogIntervalosData = [];
          this.qtdIntervalos = 0;
          this.dialogIntervalos = true;
        })
        .catch(() => {
          this.$toast.error(
            `Erro ao buscar os valores mínimo e máximo do campo ${this.campoParaClassificacao}.`,
            '',
            { position: 'topRight' }
          );
          this.limparFeicoes();
        })
        .finally(() => (this.loading = false));
    },

    setCampoParaClassificacao(campo) {
      this.$store.dispatch(
        'setSimbologiaConfigGraduadaCampoParaClassificacao',
        campo
      );
    },

    setIconesCoresFeicoes(novosIconesCoresFeicoes) {
      this.$store.dispatch(
        'setSimbologiaConfigGraduadaIconesCoresFeicoes',
        novosIconesCoresFeicoes
      );
    },

    setIconeCorOutrasFeicoes(novoIconeCorOutrasFeicoes) {
      let aux = this.iconeCorOutrasFeicoes;

      if (novoIconeCorOutrasFeicoes.icone)
        aux.icone = novoIconeCorOutrasFeicoes.icone;
      if (typeof novoIconeCorOutrasFeicoes.icone == 'undefined') aux.icone = '';
      if (novoIconeCorOutrasFeicoes.cor)
        aux.cor = novoIconeCorOutrasFeicoes.cor;

      this.$store.dispatch(
        'setSimbologiaConfigGraduadaIconeCorOutrasFeicoes',
        aux
      );
    },

    setValorMinimo(valor) {
      this.$store.dispatch('setSimbologiaConfigGraduadaValorMinimo', valor);
    },

    setValorMaximo(valor) {
      this.$store.dispatch('setSimbologiaConfigGraduadaValorMaximo', valor);
    },

    openDialogAdicionar() {
      this.$set(this.dialogsData.range, 0, this.valorMinimo);
      this.$set(this.dialogsData.range, 1, this.valorMaximo);
      this.dialogsData.cor = '#000';
      this.dialogsData.icone = '';
      this.dialog = true;
    },

    adicionar() {
      let { range, cor, icone } = { ...this.dialogsData };
      let iconesCoresFeicoes = [...this.iconesCoresFeicoes];
      iconesCoresFeicoes.push({
        range: [...range],
        cor,
        icone
      });
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
      this.dialog = false;
    },

    remover(index) {
      let iconesCoresFeicoes = [...this.iconesCoresFeicoes];
      iconesCoresFeicoes.splice(index, 1);
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    atualizaIntervalo(index, novoIntervalo) {
      let iconesCoresFeicoes = [...this.iconesCoresFeicoes];
      iconesCoresFeicoes[index].range = [
        Number(novoIntervalo[0]),
        Number(novoIntervalo[1])
      ];
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    atualizaCor(index, novaCor) {
      let iconesCoresFeicoes = [...this.iconesCoresFeicoes];
      iconesCoresFeicoes[index].cor = novaCor;
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    atualizaIcone(index, novoIcone) {
      let iconesCoresFeicoes = [...this.iconesCoresFeicoes];
      iconesCoresFeicoes[index].icone = novoIcone;
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    limparFeicoes() {
      this.setIconesCoresFeicoes([]);
      this.setIconeCorOutrasFeicoes({ icone: undefined, cor: corPadrao });
    },

    manageIntervalos(quantidade = 1) {
      if (isNaN(parseInt(quantidade))) return;

      if (quantidade == 0) {
        this.dialogIntervalosData = [];
        this.qtdIntervalos = 0;
        return;
      }

      for (let i = 1; i <= quantidade; i++) {
        if (quantidade > this.dialogIntervalosData.length) {
          this.dialogIntervalosData.push({ icone: '', cor: corPadrao });
        } else if (quantidade < this.dialogIntervalosData.length) {
          this.dialogIntervalosData.pop();
        }
      }

      this.qtdIntervalos = this.dialogIntervalosData.length;
    },

    adicionarConjuntoIntervalos() {
      this.loadingIntervalos = true;

      let novosIconesCoresFeicoes = [];

      if (this.valorMinimo < this.valorMaximo) {
        let qtdIntervalos = this.dialogIntervalosData.length;
        let tamanhoUnitarioInvervalo = this.valorMaximo / qtdIntervalos;
        let precision = (this.valorMinimo + '').split('.');
        precision = precision[precision.length - 1].length;

        let previousMin = this.valorMinimo;
        let previousMax = tamanhoUnitarioInvervalo;

        this.dialogIntervalosData.forEach((intervaloData, i) => {
          if (i > 0) {
            let maxExploded = previousMax.toFixed(precision).split('.');
            let lenMaxExploded = maxExploded[1].length;
            maxExploded[1] = Number(maxExploded[1]) + 1;
            maxExploded[1] = String(maxExploded[1]).padStart(
              lenMaxExploded,
              '0'
            );
            let resultPreviousMaxIncrementado = Number(maxExploded.join('.'));

            previousMin = resultPreviousMaxIncrementado;
            previousMax = previousMin + tamanhoUnitarioInvervalo;
          }

          novosIconesCoresFeicoes.push({
            range: [
              Number(previousMin.toFixed(precision)),
              Number(previousMax.toFixed(precision))
            ],
            cor: intervaloData.cor,
            icone: intervaloData.icone ? intervaloData.icone : ''
          });
        });
      }

      this.setIconesCoresFeicoes(novosIconesCoresFeicoes);
      this.setIconeCorOutrasFeicoes({ icone: '', cor: corPadrao });
      this.dialogIntervalos = false;
      this.loadingIntervalos = false;
    }
  }
};
</script>

<style>
.align-center-tfnumber input {
  text-align: center !important;
}
.tfnumber-label {
  text-align: center !important;
  font-size: 15px !important;
}
.input-range-slider-sg input {
  white-space: nowrap;
  overflow: hidden;
  min-width: 50px;
  text-overflow: ellipsis;
}
.input-range-slider-sg input:hover {
  cursor: text !important;
}
</style>
